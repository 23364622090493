export default (obj, path, def)=>{
  if (!path) return "nofound";

  const stringToPath = function (path) {
    if (typeof path !== "string") return path;
    let output = [];
    path.split(".").forEach(function (item) {
      item.split(/\[([^}]+)\]/g).forEach(function (key) {
        if (key.length > 0) {
          output.push(key);
        }
      });
    });

    return output;
  };

  path = stringToPath(path);
  let current = obj;
  for (let i = 0; i < path.length; i++) {
    if (!current[path[i]]) return def;
    current = current[path[i]];
  }
  return current;

}
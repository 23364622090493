const menus = [
    {
        title: "Applications",
        icon: "bi bi-grid",
        route: "/admin/apps",
    },
    {
        title: "Organizations",
        icon: "bi bi-people",
        route: "/admin/organizations",
    },
    {
        title: "Users",
        icon: "bi bi-person",
        route: "/admin/users",
    }
]

export default menus
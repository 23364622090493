import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Input } from '../../components/Input'
import { getStorage } from '../../utils/storage'
import "./style.css"

const SettingsPage = (props) => {
  const [user, setUser] = useState({})

  useEffect(()=>{
    const getUser = async()=>{
      const { user } = await getStorage()

      setUser(user)
    }

    getUser()
  }, [])
  return (
    <div className="row justify-content-center">
      <div className="col-md-8">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <i className="bx bx-card" style={{ marginRight: 5 }}></i>
                Profile Info
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="row">
                  <div className="col-md-6">
                    <Input
                      label="Firstname"
                      type="text"
                      disabled
                      value={user.firstName}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      label="Lastname"
                      type="text"
                      disabled
                      value={user.lastName}
                    />
                  </div>
                  
                  <div className="col-md-6">
                    <Input
                      label="Email Address"
                      disabled
                      value={user.email}
                    />
                  </div>

                  <div className="col-md-6">
                    <Input
                      label="Phone number"
                      disabled
                      value={user.phone}
                    />
                  </div>

                  {/* <div className="col-md-12">
                    <Button 
                      onPress={this.handleSubmitProfile.bind(this)}
                      disabled={this.state.disableEdit}
                      className="btn-primary"
                      text="Edit Profile"/>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsPage
import React, { useState, useRef, useEffect } from 'react'
import Header from '../../components/Header'
import AppCard from './AppCard'
import mockApps from "../../data/mockApps"
import "./style.css"
import App from './App'
import Spinner from '../../components/Spinner'
import axios from 'axios'
import { APPS_API, TOKEN } from '../../data/api'

const AppsPage = () => {
  const appRef = useRef(null)
  const [apps, setApps] = useState([])

  // const [apps, setApps] = useState=([])
  const [selected, setSelected] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    if(selected&&selected.name){
      let classList = appRef.current.classList
      if(!classList) return 
      if(classList.contains("d-none")){
        classList.remove("d-none")
      }
    }

    getApps()
  }, [selected])

  const getApps = async()=>{
    const options = {
      url: APPS_API+"/applications",
      method: "GET",
      withCredentials: true,
      params: {
        limit: 500
      },
      headers : { authorization :`Bearer ${TOKEN}`}
    }

    axios(options)
    .then(res=>{
      setLoading(false )
      if(res.data){
        setApps(res.data.results)
      }
    })
    .catch(err=>{
      setLoading(false )
      console.log("Apps err: ", err)
    })
  }

  const handleAdd = async()=>{
    setSelected({})
    let classList = appRef.current.classList
    if(!classList) return
    if(classList.contains("d-none")){
      classList.remove("d-none")
    }
  }


  const handleClose=()=>{
    let classList = appRef.current.classList
    if(!classList) return
    if(classList.contains("d-none")) return
    classList.add("d-none")
    setSelected({})
  }

  return (
    <>
    {
      loading ? 
      <Spinner height={"50vh"} />
      :
      <>
      <Header 
        addButton={true}
        addText="Add Application"
        handleAdd={handleAdd}

      />
      <div className='apps-container'>

        <div className='apps-body'>
          {
            apps.length > 0 ?
            apps.map((app, index)=>{
              return (
                <AppCard 
                  app={app}
                  key={index}
                  cardClick={setSelected}
                  appRef={appRef}
                />
              )
            }):
            <p>No Application found</p>
          }
        </div>
        <div ref={appRef} className='selected-app d-none'>
          <App 
            app={selected}
            getApps={getApps}
            handleClose={handleClose}
          />
        </div>

        
      </div>
      </>
    } 
    </>
  )
}

export default AppsPage
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import menus from '../../data/menus'
import options from '../../data/options'
import logout from '../../utils/logout'
import './style.css'


const Sidebar = (props) => {
  const { pathname, hideOnMobile } = props

  const signout = ()=>{
    //REMOVE COOKIES, TOKENS AND EXIT
    logout()
  }

  return (
    <div id='sidebar' className='sidebar py-4'>

      <div className="top">
        <div className="px-2">
          <img src="/mobitill_logo.png" alt="MobiTill Logo" />
        </div>
      </div>
      <div className="middle">
        <ul>
          {
            menus.map((menu, index)=>{
              return (
                <li key={index}>
                  <Link
                    to={menu.route}
                    title={menu.title}
                    className={pathname === menu.route ? "active" : ""}
                    onClick={hideOnMobile}
                  >
                    <div className="s-li-icon">
                      <i className={menu.icon}></i>
                    </div>
                    <span>{menu.title}</span>
                  </Link>
                </li>
              )
            })
          }
        </ul>
      </div>
      <div className="bottom">
        <ul>
          {
            options.map((option, index)=>{
              return (
                <li key={index}>
                  <Link
                    to={option.route}
                    title={option.title}
                    className={pathname === option.route ? "active" : ""}
                    onClick={hideOnMobile}
                  >
                    <div className="s-li-icon">
                      <i className={option.icon}></i>
                    </div>
                    <span>{option.title}</span>
                  </Link>
                </li>
              )
            })
          }
          <li>
            <Link
              to={'/'}
              title={"Log Out"}
              onClick={signout}
            >
              <div className="s-li-icon">
                <i className={"bi bi-box-arrow-right"}></i>
              </div>
              <span>{"Log Out"}</span>
            </Link>

          </li>
        </ul>
      </div>

    </div>
  )
}

export default Sidebar
import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import './style.css'
import { useLocation } from 'react-router-dom'
import getAppTitle from '../../utils/getAppTitle'
import { AUTH_API, TOKEN } from '../../data/api'
import axios from 'axios'
import { setStorage } from '../../utils/storage'

const Layout = ({ children }) => {
  const path = useLocation()
  const [title, setTitle] = useState("")

  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    const title = getAppTitle(path.pathname)
    setTitle(title)
  }, [path])
  
  useEffect(()=>{
    getProfile()
  }, [])

  async function getProfile(){
    const options = {
      url: AUTH_API+"/auth/profile",
      method: 'GET',
      withCredentials: true,
      headers: { Authorization: `Bearer ${TOKEN}`}  
    }

    await axios(options)
    .then(async res=>{
      //check if user has rights to application
      if(res.data){
        setLoading(false)
        if(res.data){
          const user = res.data
          if(user.role&&user.role === "admin"){
            await setStorage({ user: res.data })
            setLoading(false)
            return
          }
          window.location.href = "/"
        }
      }
    })
    .catch(err=>{
      console.log("Error: ", err)
      window.location.href = "/"
    })
  }
 
  function onToggle (){
    const sidebar_class = document.getElementById("sidebar").classList;
    if (sidebar_class.contains("show")) {
      sidebar_class.remove("show");
      sidebar_class.add("hide");

      return;
    }

    sidebar_class.add("show");
    sidebar_class.remove("hide");
  }

  function hideOnMobile(){
    const screenWidth = window.screen.availWidth;
    const sidebar_class = document.getElementById("sidebar").classList;
    if (screenWidth < 600 && sidebar_class.contains("show")) {
      sidebar_class.remove("show");
      sidebar_class.add("hide");

      return;
    }

  }

  if(loading){
    return null
  }

  return (
    <div className='layout'>
      <div className="container-fluid p-0 d-flex overflow-hidden w-100">
        <aside>
          <Sidebar 
            pathname={path.pathname}
            hideOnMobile={hideOnMobile}
          />
        </aside>
        <div className='main-section'>
          <nav>
            <Navbar 
              title={title}
              onToggle={onToggle}
            />
          </nav>
          <section>
            <div className='px-3'>
              { children }
            </div>
          </section>
        </div>        
      </div>
    </div>
  )
}

export default Layout
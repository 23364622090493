import React from 'react'
import Select from './Select'
import { countries } from '../../data/countries'
import "./style.css"

const Input = (props) => {
    return (
      <div className="form-group has-error" style={props.inputContainerStyle}>
        {props.label && (
          <div className={`input-label ${props.hidden && ' hidden '}`}>
            <label>
              {props.label}{" "}
              {props.required && <span className="required">*</span>}
            </label>
          </div>
        )}

        <div className="input-content">
          {props.isPhone && (
            <div className="countries no-border-bottom">
              <Select
                value={props.countryValue}
                onChange={props.countryOnchange}
                error={props.countryError}
                name={props.countryName}
                options={countries}
                isDisabled={props.disabled}
              />
            </div>
          )}
          {props.iconLeft && (
            <div className="iconLeft">
              <i className={props.iconLeft}></i>
            </div>
          )}
          <input
            type={props.type ? props.type : "text" }
            className={`form-control 
            ${props.className} ${props.error && "is-invalid "} 
            ${props.iconLeft && " left-icon "}
            ${props.borderBottom ? "input-border-bottom" : "input-all-border"}
            `}
            value={props.value}
            onChange={props.onChange}
            maxLength={props.maxLength}
            name={props.name}
            onClick={props.onClick}
            placeholder={props.placeholder}
            required={props.required}
            disabled={props.disabled}
          />
          {props.iconRight && (
            <div className="iconRight">
              <i className={props.iconRight}></i>
            </div>
          )}
        </div>
        {props.error && <div className="text-danger">{props.error}</div>}
      </div>
    );
}

export default Input
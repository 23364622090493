import React from 'react'
import { Spinner } from 'react-bootstrap';


const SpinnerLoader = (props) => {
  const { variants, size , role, as, height, center } = props

  let style = {}

  if(height){
    style.height = height
  }


  return (
    <div style={style} className={`d-flex align-items-center ${center? 'justify-content-start' : 'justify-content-center'}`}>
      <Spinner animation="border" as={as} role={role} size={size} variant={variants ? variants : "danger"} />
    </div>
  )
}

export default SpinnerLoader
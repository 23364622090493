import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Modal from '../../components/Modal'
import Spinner from '../../components/Spinner'
import Table from '../../components/Table'
import { TOKEN, USERS_API } from '../../data/api'
import User from './User'
import toastMessage from "../../utils/toastMessage"

const UsersPage = () => {

  const [users, setUsers] = useState([])
  const [pagination, setPagination] = useState({})
  const [selected, setSelecetd] = useState()
  const [searchText, setSearch] = useState("")
  const [loading, setLoading] = useState(true)
  const [showAdd, setShowAdd] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const[showFIlter, setShowFilter] = useState(false)

  useEffect(()=>{
    getUsers()
  }, [])

  const getUsers = async(params)=>{
    setLoading(true)
    const options = {
      url: USERS_API+"/users",
      method: "GET",
      withCredentials: true  ,
      headers: { Authorization: `Bearer ${TOKEN}`} ,
      params: {
        limit: 15,
        ...params
      }   
    }

    axios(options)
    .then(res=>{
      setLoading(false )
      if(res.data&&res.data.results){
        const  { results } = res.data
        let orgs = []

        // results.forEach(result=>{
        //   result.organizations.forEach(org=>{
        //     orgs.push(`${org.name} / `)
        //   })
        //   let organization = [...orgs]
        //   result.organization = organization
        // })

        let pg = {}

        pg.pageNumber = res.data.page
        pg.pageCount = res.data.totalPages
        pg.pageNumber = res.data.page
        pg.pageLimit = res.data.limit
        pg.totalCount = res.data.totalResults

        setPagination(pg)
        setUsers(results)
      }
    })
    .catch(err=>{
      setLoading(false )
      console.log("User err: ", err)
    })
  }
  

  const handleSearch = async(e)=>{
    setSearch(e.target.value)
  }

  const handleAdd = ()=>{
    setShowAdd(true)
  }

  const handleFilter = ()=>{
    setShowFilter(true)
  }

  const handleDownload = async()=>{}

  const handleEdit = async (user)=>{
    await setSelecetd(user)
    setShowAdd(true)
  }

  const handleDelete = (user)=>{
    if(!user || !user.id) return
    setDeleting(true)

    const options = {
      url: USERS_API+"/users/"+user.id,
      method: "DELETE",
      withCredentials: true,
      headers: { Authorization: `Bearer ${TOKEN}`}
    }

    axios(options)
      .then(res=>{
        setDeleting(false)
        if(res.status === 204){
          toastMessage(
            "success",
            "User deleted successfully"
          )
          getUsers()
        }
      })
      .catch(err=>{
        setDeleting(false)
        if(!err.response){
          return (
            toastMessage("error", "Connection failed. This could be an issue with the internet connection or the resource is currently unavailable.")
          )
        }
        toastMessage(
          "error",
          err.response.data ? err.response.data.message  : err.message ? err.message : "Failed. Please try again"
        )
      })
  }

  const handlePagination =(page)=>{
    setLoading(true)
    getUsers({ page })
  }

  const headers = [
    { title: "First Name", key: "firstName" },
    { title: "Last Name", key: "lastName" },
    { title: "Email", key: "email" },
    { title: "Phone", key: "phone" },
    { title: "Organization", key: "organizations" },
    { title: "Role", key: "role" },
    { title: "Action", key: "action" },
  ]

  return (
    <>
      <Table 
        data={users}
        pagination={pagination}
        handlePagination={handlePagination}
        headers={headers}
        searchText={searchText}
        handleSearch={handleSearch}
        handleAdd={()=>setShowAdd(true)}
        handleFilter={handleFilter}
        handleDownload={handleDownload}
        isLoading={loading}
        addButton={true}
        filterButton={true}
        downloadButton={true}
        actions={[
          // {
          //   name: "View",
          //   onClick: (e)=>handleView(e)
          // },
          {
            name: "Edit",
            onClick: handleEdit
          },
          {
            name: deleting ?
                    <Spinner center size="sm" /> :
                    "Delete",
            onClick: handleDelete
          },
        ]}
      />

      <Modal 
        show={showAdd}
        title="Add User"
        handleClose={()=> (setShowAdd(false), setSelecetd(null))}
      >
        <User
          selected={selected}
          getUsers={getUsers}
          handleClose={()=>(setShowAdd(false), setSelecetd(null))}
        />
      </Modal>

    </>
  )
}

export default UsersPage
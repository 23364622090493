import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Button } from '../../components/Button'
import { Select } from '../../components/Input'
import { APPS_API, TOKEN } from '../../data/api'
import toastMessage from '../../utils/toastMessage'

const AddOrgApp = (props) => {
  const { org, handleClose, setActive } = props

  const [organization, setOrganization] = useState({})
  const [apps, setApps] = useState([])
  const [app, setApp] = useState({})
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState({})

  useEffect(()=>{
    let selected = org
    selected.label = org.name
    selected.value = org.id
    setOrganization(selected)
    getApps()
  }, [])

  const getApps = async()=>{
    setLoading(true)
    const options = {
      url: APPS_API+"/applications",
      method: "GET",
      withCredentials: true,
      params: {
        limit: 50
      },
      headers : { authorization :`Bearer ${TOKEN}`}
    }

    axios(options)
    .then(res=>{
      setLoading(false )
      if(res.data){
        const { results } = res.data
        if(results&&Array.isArray(results)){
            results.forEach(result=>{
                result.label = result.name
                result.value = result.id
            })
            setApps(results)
        }
      }
    })
    .catch(err=>{
      setLoading(false )
      console.log("Apps err: ", err)
    })
  }

  const handleSelect = (value, { name })=>{
    console.log("Value: ", value)
    console.log("Name: ", name)

    if(error[name])
        delete error[name]

    setApp(value)
  }

  const handleSubmit = async(e)=>{

    if(!app.value){
        setError(prev=>({
            ...prev,
            application: "Application MUST be selected"
        }))
        return
    }

    setSubmitting(true)

    const options = {
        url: APPS_API+`/applications/${app.value}/organization-application/${org.id}`,
        headers : { authorization :`Bearer ${TOKEN}`},
        method: "POST",
        withCredentials: true       
    }

    axios(options)
        .then(async(res)=>{
            setSubmitting(false)
            if(res.status === 201){
                toastMessage(
                    "success",
                    "Application successfully added to the organization"
                )
                await setActive("applications")
                setActive(null)
            }
            console.log("Res: ", res)
        })
        .catch((err)=>{
            setSubmitting(false)
            if(!err.response){
              return (
                toastMessage("error", "Connection failed. This could be an issue with the internet connection or the resource is currently unavailable.")
              )
            }
            console.log("Add Orgs err: ", err.response)
            toastMessage(
              "error",
              err.response.data ? err.response.data.message  : err.message ? err.message : "Failed. Please try again"
            )
        })

  }

  return (
    <>
    <div className='card-body'>
        <form className='row'>
            <div className="col-md-6">
                <Select 
                    label="Organization: "
                    required
                    isDisabled
                    options={apps}
                    value={organization}
                    name={'organization'}
                    // isLoading={loading}
                    onChange={handleSelect} 
                />
                
            </div>
            <div className="col-md-6">
                <Select 
                    label="Application: "
                    required
                    options={apps}
                    value={app}
                    error={error.application}
                    // async
                    name={'application'}
                    isLoading={loading}
                    onChange={handleSelect} 
                />

            </div>
            
        </form>
    </div>
    <div className="modal-footer">
        <Button 
            className='btn-secondary'
            text="Close"
            onClick={handleClose}
        />
        <Button 
            className='btn-primary'
            text="Submit"
            isSubmitting={submitting}
            onClick={handleSubmit}
        />
    </div>
    </>
  )
}

export default AddOrgApp
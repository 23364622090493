import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"

import './App.css'

import Layout from "./components/Layout";


import SplashPage from "./pages/SplashPage"
import AppsPage from "./pages/AppsPage"
import OrganizationsPage from "./pages/OrganizationsPage";
import UsersPage from "./pages/UsersPage/UsersPage";
import SettingsPage from "./pages/SettingsPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



const createBrowserHistory = require("history").createBrowserHistory;


function App() {
  return (
    <main>
      <Router history={createBrowserHistory({ queryKey: false })}>
        <Switch>
          <Route exact path='/' component={SplashPage} />
          <Route
            path="/admin"
            render={(match) => {
              const pathname = match.location ? match.location.pathname : "";
              return (
                <>
                <Layout>
                  <Route
                    exact
                    path='/admin/apps'
                    component={AppsPage}
                    active={pathname.includes("apps")}
                  />
                  <Route
                    exact
                    path='/admin/organizations'
                    component={OrganizationsPage}
                    active={pathname.includes("organizations")}
                  />
                  <Route
                    exact
                    path='/admin/users'
                    component={UsersPage}
                    active={pathname.includes("users")}
                  />
                  <Route
                    exact
                    path='/admin/settings'
                    component={SettingsPage}
                    active={pathname.includes("settings")}
                  />
                </Layout>
              </>
              )
            }}
          />
        </Switch>
      </Router>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
      />
    </main>
  );
}

export default App;

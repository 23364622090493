import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button } from '../../components/Button'
import { Input } from '../../components/Input'
import { ORGANIZATIONS_API, TOKEN } from '../../data/api'
import toastMessage  from "../../utils/toastMessage"

const Organization = (props) => {
  const { selectedOrg, handleClose, getOrgs, disabled } = props
  const [loading, setLoading] = useState(false)


  const defaultOrg = {
    name: "",
    contactPersonFirstName: "",
    contactPersonLastName: "", 
    email: "",
    phone: "",
    address: "",
    websiteUrl: ""
  }
  const [org, setOrg] = useState(defaultOrg)
  const [err, setErr] = useState({})

  useEffect(()=>{
    if(selectedOrg){
        setOrg(selectedOrg)
    }
  }, [])

  const handleInput = (e)=>{
    if(!e || !e.target) return
    const { name, value } = e.target
    if(err[name]){
        delete err[name]
    }

    setOrg(prev=>({
        ...prev,
        [name]: value
    }))
  }

  const validateOrg = async()=>{
    let error = {}, valid = true
    const {
        name,
        contactPersonFirstName,
        contactPersonLastName,
        email,
        phone,
        address,
        websiteUrl
    } = org

    if(!name || name === ""){
        error.name = "Organization name is required"
        valid = false
    }
    if(!contactPersonFirstName || contactPersonFirstName === ""){
        error.contactPersonFirstName = "Organization contact person name is required"
        valid = false
    }
    if(!contactPersonLastName || contactPersonLastName === ""){
        error.contactPersonLastName = "Organization contact person name is required"
        valid = false
    }
    if(!email || email === ""){
        error.email = "Organization email is required"
        valid = false
    }
    if(!phone || phone === ""){
        error.phone = "Organization phone number is required"
        valid = false
    }
    if(!address || address === ""){
        error.address = "Organization address is required"
        valid = false
    }
    if(!websiteUrl || websiteUrl === ""){
        error.websiteUrl = "Organization website link is required. If absent, kindly insert N/A"
        valid = false
    }

    await setErr((prev)=>({
        ...prev,
        ...error
    }))

    return valid
  }

  const handleSubmit = async(e)=>{
    e.preventDefault()

    let isValid = await validateOrg()
    if(!isValid) return
    setLoading(true)

    let url = selectedOrg&&selectedOrg.id ? ORGANIZATIONS_API+"/organizations/"+selectedOrg.id : ORGANIZATIONS_API+"/organizations"
    let method = selectedOrg ? "PATCH" : "POST"

    const {
        name,
        contactPersonFirstName,
        contactPersonLastName, 
        email,
        phone,
        address,
        websiteUrl
    } = org

    const options = {
      url,
      method,
      headers : { authorization :`Bearer ${TOKEN}`},
      data: {
        name,
        contactPersonFirstName,
        contactPersonLastName, 
        email,
        phone,
        address,
        websiteUrl
      }
      // withCredentials: true       
    }

    axios(options)
    .then(res=>{
      setLoading(false )
      console.log("Add Orgs: ", res)
      if(res.status === 201 || res.status === 200){
        toastMessage(
            "success",
            selectedOrg ? 
                "Organization updated successfully":
                "Organization added successfully"
        )
        getOrgs()
        handleClose()
      }
    })
    .catch(err=>{
      setLoading(false )

      if(!err.response){
        return (
            toastMessage("error", "Connection failed. This could be an issue with the internet connection or the resource is currently unavailable.")
        )
      }
      console.log("Add Orgs err: ", err.response)
      toastMessage(
        "error",
        err.response.data ? err.response.data.message  : err.message ? err.message : "Failed. Please try again"
        )
    })

  }

  return (
    <>
    <div className='card-body'>
        <form className='row'>
            <Input
                name="name"
                value={org.name}
                onChange={handleInput}
                error={err.name}
                label='Organisation Name:'
                disabled={disabled}
                required
            />
            <div className="col-md-6">
                <Input
                    name="contactPersonFirstName"
                    value={org.contactPersonFirstName}
                    onChange={handleInput}
                    error={err.contactPersonFirstName}
                    label='Contact Person First Name:'
                    required
                    disabled={disabled}
                />
            </div>
            <div className="col-md-6">
                <Input
                    name="contactPersonLastName"
                    value={org.contactPersonLastName}
                    onChange={handleInput}
                    error={err.contactPersonLastName}
                    label='Last Name:'
                    disabled={disabled}
                    required
                />
            </div>
            
            
            <Input
                name="address"
                value={org.address}
                onChange={handleInput}
                error={err.address}
                label='Address:'
                disabled={disabled}
                required
            />
            <Input
                name="email"
                value={org.email}
                onChange={handleInput}
                error={err.email}
                label='Email:'
                required
                disabled={disabled}
            />
            <Input
                name="phone"
                value={org.phone}
                onChange={handleInput}
                error={err.phone}
                label='Phone:'
                maxLength={10}
                required
                disabled={disabled}
            />
            <Input
                name="websiteUrl"
                value={org.websiteUrl}
                onChange={handleInput}
                error={err.websiteUrl}
                label='Website URL:'
                required
                disabled={disabled}
            />
        </form>
    </div>
    <div className="modal-footer">
        <Button 
            className='btn-secondary'
            text="Close"
            onClick={handleClose}
        />
        <Button 
            className='btn-primary'
            text={selectedOrg ? "Update" : "Submit"}
            disabled={disabled}
            isSubmitting={loading}
            onClick={handleSubmit}
        />
    </div>
    </>
  )
}

export default Organization
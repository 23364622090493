import React from 'react'

const AppCard = (props) => {
  const { name, cardClick, description, url, app } = props
  return (
    <div onClick={()=>cardClick(app)} className='app-card'>
        <div className='app-image'>
            <img src={app.image} alt="" />
        </div>
        <div className='app-body'>
            <h3>{app.name}</h3>
            {/* <p>{app.description}</p> */}
            <p className='app-link'>{app.applicationUrl}</p>
        </div>
    </div>
  )
}

export default AppCard
import React from 'react'
import "./style.css"

const Pagination = ({ pagination, handlePagination }) => {

  if(!pagination) return null

  const { pageNumber, pageLimit, pageCount, totalCount } = pagination

  const paginate =(page)=>{
    if(!pageNumber) return
    if(!handlePagination) return

    if(page === "prev"){
      if(!pageNumber ||pageNumber <= 1) return
      handlePagination(pageNumber - 1)
    }

    if(pageCount <= pageNumber) return
    handlePagination(pageNumber + 1)
  }

  return (
    <div className='pagination-container'>
      <button onClick={()=>paginate("prev")} disabled={!pageNumber ||pageNumber <= 1}>
        <i className="bi bi-chevron-left"></i>
        Prev
      </button>
      <div className='page-counter'>
        <span>{pageNumber}</span>
        <i className="bi bi-dash-lg"></i>
        <span>{pageCount}</span>
      </div>
      <button onClick={()=>paginate("next")} disabled={pageCount <= pageNumber}>
        Next
        <i className="bi bi-chevron-right"></i>
      </button>
      <div className='total-count'>
        <p>of </p>
        <span>{totalCount}</span>
      </div>
    </div>
  )
}

export default Pagination
import React, { useState } from 'react'
import { Tabs } from "../../components/Tabs"
import AddOrgApp from './AddOrgApp'
import Organization from './Organization'
import OrganizationApps from './OrganizationApps'

const ViewOrganization = (props) => {
  const { selectedOrg, handleClose } = props

  const [active, setActive] = useState(null)

  const renderOrganization = ()=>{
    return (
        <Organization
            disabled
            handleClose={handleClose}
            selectedOrg={selectedOrg}
        />
    )
  }

  const renderApps =()=>{
    return(
        <OrganizationApps 
            org={selectedOrg}
            setActive={setActive}
            active={active}
            handleClose={handleClose}
        />
    )
  }

  const renderAddApp =()=>{
    return(
        <AddOrgApp 
            org={selectedOrg}
            setActive={setActive}
            handleClose={handleClose}
        />
    )
  }


  let options = [
    {
        title: `Organization`,
        data: renderOrganization()
    },
    {
        title: `Applications`,
        data: renderApps()
    },
    {
        title: `Add Application`,
        data: renderAddApp()
    },
  ]

  return (
    <Tabs options={options} activeTab={active} />
  )
}

export default ViewOrganization
import React from "react";
import Spinner from "../Spinner";
import "./styles.css";

const Button = (props) => (
  <button
    className={`btn ${props.className}  ${props.circle && "circle"}`}
    disabled={props.isSubmitting || props.disabled}
    onClick={props.onClick}
  >
    {props.isSubmitting ? (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
      />
    ) : (
      <span>
        {props.icon && <i className={props.icon}></i>}
        <span style={props.icon && { marginLeft: 5 }}>
          {props.text && props.text}
          {props.selected && (
            <b style={{ paddingLeft: 5 }}>{props.selected}</b>
          )}
        </span>
      </span>
    )}
  </button>
);

export default Button;

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Spinner from "../../components/Spinner"
import { useCookies } from 'react-cookie';
import { AUTH_API, TOKEN } from '../../data/api';
import { setStorage } from '../../utils/storage';
import "./style.css"

const SplashPage = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['AuthorizationRefresh']);
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")

  useEffect(()=>{
    getProfile()
  }, [])

  async function getProfile(){
    const options = {
      url: AUTH_API+"/auth/profile",
      method: 'GET',
      withCredentials: true,
      headers: { Authorization: `Bearer ${TOKEN}`}   
    }

    await axios(options)
    .then(async res=>{
      //check if user has rights to application
      if(res.data){
        const user = res.data
        if(user.role&&user.role === "admin"){
          await setStorage({ user: res.data })
          setLoading(false)
          window.location.href="/admin/apps"
          return
        }
        window.location.href = "/"
      }  
    })
    .catch(err=>{
      setError(err.message)
      if(err.response){
        setLoading(false)
        setError(err.response.data.message)
      }
    })
  }

  function handleRedirect(){
    window.location.href = 'https://accounts.mobitill.com'
  }

  return (
    <div className='splash_body gap-5'>
      <img src="/dil_logo.png" alt="DIL LOGO"/>
      {
        loading &&
        <Spinner />
      }

      {
        error&&
        <div className='error-body'>
          <p className='text-danger'>Error: {error}</p>
          <p className=''>
             To authenticate visit: <a href="https://accounts.mobitill.com">MobiTill accounts</a>
          </p>
        </div>
      }
    </div>
  )
}

export default SplashPage
import React from 'react'
import { Input } from '../Input'
import './style.css'

const Header = (props) => {
  const { 
    searchText, 
    showSearch,
    handleSearch,
    addButton,
    addText,
    downloadButton,
    filterButton ,
    handleFilter,
    handleAdd,
    handleDownload,

  } = props

  return (
    <div className={`container-fluid  header ${!showSearch&&'justify-content-end pb-3'}`}>
      {
        showSearch&&
        <div className="col-md-3">
          <Input
            value={searchText}
            onChange={handleSearch} 
            placeholder={"Search..."}
            iconLeft="bi bi-search"
          />
        </div>
      }
      {
        (addButton || downloadButton || filterButton) &&
        <div className="icons-container">
          { downloadButton&&
           ( 
            <span className='p-2' onClick={handleDownload}>
              <i className="bi bi-download"></i>
            </span>)
          }
          { filterButton&&
          (
            <span className='p-2' onClick={handleFilter}>
              <i className="bi bi-filter"></i>
            </span>
          )}
          { addButton&& 
          (
            <span className='p-2' onClick={handleAdd} >
              <i className="bi bi-plus">{addText}</i>
            </span>
          )}
        </div>
      }

    </div>
  )
}

export default Header
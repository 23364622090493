import menus from "../data/menus";
import options from "../data/options";

export default (pathname) => {
  let title = "";

  menus.map(menu=>{
    if(menu.route === pathname){
        title = menu.title
    }
  })

  if(title === ""){
    options.map(option=>{
        if(option.route === pathname){
            title = option.title
        }
    })
  }

  return title
};

import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button } from '../../components/Button'
import { Input, Select } from '../../components/Input'
import { ORGANIZATIONS_API, TOKEN, USERS_API } from '../../data/api'
import toastMessage from '../../utils/toastMessage'

const roles = [
    { value: "user", label: "User" },
    { value: "admin", label: "Admin" },
    { value: "organizationAdmin", label: "Organizational Admin" }
]

const User = (props) => {

  const { selected, handleClose, getUsers } = props

  const defaultUser = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    organizationId: [],
    role: {}
  } 
  const [user, setUser] = useState(defaultUser)
  const [error, setError] = useState({})
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [orgs, setOrgs] = useState([])

  useEffect(()=>{
    if(selected&&selected.id){
      let passedUser = { ...selected }
      let role = {}
      role.label = passedUser.role
      role.value = passedUser.role
      passedUser.role = role
      
      let organizations = []
      passedUser.organizations&&
      passedUser.organizations.length > 0 && 
      Array.isArray(passedUser.organizations)&&
      passedUser.organizations.forEach(org=>{
        let data = {}
        data.label = org.name
        data.value = org.id
        organizations.push(data)
      })
      passedUser.organizationId = organizations
      setUser(passedUser)
    }

    getOrganizations()
  }, [])

  const getOrganizations = async(params)=>{

    setLoading(true)

    const options = {
      url: ORGANIZATIONS_API+"/organizations",
      headers : { authorization :`Bearer ${TOKEN}`},
      method: "GET",
      withCredentials: true,
      params: {
        ...params
      }      
    }

    return axios(options)
    .then(async res=>{
      setLoading(false )
      if(res.data&&res.data.results&&Array.isArray(res.data.results)){
        const { results } = res.data

        await results.forEach(result=>{
            result.label = result.name
            result.value = result.id
        })

        setOrgs(res.data.results)
      }
    })
    .catch(err=>{
      setLoading(false )
      console.log("Orgs err: ", err)
    })
  }

  const handleInput = (e)=>{
    if(!e || !e.target) return

    const { name, value } = e.target

    if(error[name])
        delete error[name]

    setUser(prev=>({
        ...prev,
        [name]: value
    }))
  }
  
  const handleSelect = (value, { name })=>{
    console.log("Name: ", name)
    console.log("Value: ", value)

    if(error[name])
        delete error[name]

    setUser(prev=>({
        ...prev,
        [name]: value
    }))

  }

  function loadOptions(inputValue, CB){
    // const value = getOrganizations({ })
    console.log("Search: ", inputValue)

    // CB(value)
  }

  const validateUser = async()=>{
    let valid = true, err = {}

    const {
        firstName,
        lastName,
        email,
        phone,
        password,
        role,
        organizationId
    } = user

    if(!firstName || firstName === ""){
        valid = false
        err.firstName = "First Name MUST be provided"
    }
    if(!lastName || lastName === ""){
        valid = false
        err.lastName = "Last Name MUST be provided"
    }
    if(!email || email === ""){
        valid = false
        err.email = "User email MUST be provided"
    }
    if(!phone || phone === ""){
        valid = false
        err.phone = "User phone number MUST be provided"
    }
    if(!selected || !selected.id){
      if(!password || password === ""){
        valid = false
        err.password = "User password MUST be provided"
      }
    }
    if(!role || ! role.value){
        valid = false
        err.role = "User role MUST be selected"
    }
    if(!organizationId[0] || !organizationId[0].value){
        valid = false
        err.organizationId = "User organization MUST be selected"
    }

    await setError(err)
    return valid
  }

  const handleSubmit = async(e)=>{
    e.preventDefault()

    const isValid = await validateUser()
    if(!isValid) return

    let url = selected&&selected.id ? USERS_API+"/users/"+selected.id :  USERS_API+"/users"
    let method = selected&&selected.id ? "PATCH" : "POST"

    setSubmitting(true)
    const options = {
      url,
      method,
      withCredentials: true  ,
      headers: { Authorization: `Bearer ${TOKEN}`},
      data :{
        email : user.email,
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        role: user.role&&user.role.value,
        organizationIds: user.organizationId.map(org=> org.id)
      }    
    }

    console.log("Options: ", options)

    axios(options)
        .then(res=>{
            setSubmitting(false)
            if(res.status === 201 || res.status === 200){
                toastMessage(
                    "success",
                    selected&&selected.id ?
                    "User updated successfully.":
                    "User added successfully."
                )
                getUsers()
                handleClose()
            }
        })
        .catch(err=>{
            setSubmitting(false)
            if(!err.response){
              return (
                toastMessage("error", "Connection failed. This could be an issue with the internet connection or the resource is currently unavailable.")
              )
            }
            console.log("Add Orgs err: ", err.response)
            toastMessage(
              "error",
              err.response.data ? err.response.data.message  : err.message ? err.message : "Failed. Please try again"
            )
        })
  }

  return (
    <>
    <div className='card-body'>
        <form className='row'>
            <Input
                label='First Name:'
                required
                name="firstName"
                value={user.firstName}
                onChange={handleInput}
                error={error.firstName}
            />
            <Input
                label='Last Name:'
                required
                name="lastName"
                value={user.lastName}
                onChange={handleInput}
                error={error.lastName}
            />
            <Input
                label='Email:'
                required
                name="email"
                value={user.email}
                onChange={handleInput}
                error={error.email}
            />
            <Input
                label='Phone:'
                required
                maxLength={10}
                name="phone"
                value={user.phone}
                onChange={handleInput}
                error={error.phone}
            />
            {
                !selected&&
                <Input
                    label='Password:'
                    required
                    minLength={6}
                    name="password"
                    value={user.password}
                    onChange={handleInput}
                    error={error.password}
                />
            }
            <Select
                label='Organization:'
                required
                async
                isLoading={loading}
                defaultOptions={orgs}
                isMulti
                name="organizationId"
                onChange={handleSelect}
                error={error.organizationId}
                value={user.organizationId}
                loadOptions={loadOptions}
            />
            <Select
                label='Role:'
                required
                name="role"
                options={roles}
                error={error.role}
                onChange={handleSelect}
                value={user.role}
            />
        </form>
    </div>
    <div className="modal-footer">
        <Button 
            className='btn-secondary'
            text="Close"
            onClick={handleClose}
        />
        <Button 
            className='btn-primary'
            text={selected&&selected.id ? "Update" : "Submit"}
            isSubmitting={submitting}
            onClick={handleSubmit}
        />
    </div>
    </>
  )
}

export default User
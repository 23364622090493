import React from 'react'
import { Link } from 'react-router-dom'
import "./style.css"

const Navbar = (props) => {

  const {
    title,
    onToggle
  } = props

  return (
    <nav className="navbar navbar-expand-lg bg-white">
      <div className="container-fluid">
        <div className="navbar-brand d-flex gap-3 align-items-center">
          <div className={`toggleBtn`} onClick={onToggle}>
            <i className={"bi bi-list"}></i>
          </div>
          <h1>{title}</h1>
        </div>

        <div className="navbar-content nav-items gap-3">
          <div className='navbar-bell'>
            <img style={{ width: '18px', height: "18px"}} src="/assets/bell.svg" alt="Notification" />
          </div>

          <Link to="/admin/settings">
            <div className="navbar-avatar">
              <span>
                JD
              </span>
            </div>
          </Link>
        </div>


      </div>
    </nav>
  )
}

export default Navbar
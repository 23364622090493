import React, { useState, useEffect } from 'react'
import { Button } from '../../components/Button'
import Table from '../../components/Table'
import { APPS_API, ORGANIZATIONS_API, TOKEN } from '../../data/api'
import axios from 'axios'
import Spinner from '../../components/Spinner'
import toastMessage from '../../utils/toastMessage'

const OrganizationApps = (props) => {
  const { org, active, setActive, handleClose } = props
  const [apps, setApps] = useState([])
  const [loading, setLoading] = useState(true)
  const [revoking, setRevoking] = useState(false)

  useEffect(()=>{
    getApps()
  }, [active])

  const getApps = async()=>{
    setLoading(true)

    const options = {
      url: ORGANIZATIONS_API+`/organizations/${org.id}/applications`,
      headers : { authorization :`Bearer ${TOKEN}`},
      method: "GET",
      withCredentials: true       
    }

    axios(options)
        .then(res=>{
            setLoading(false)
            if(res.data){
                setApps(res.data)
            }
        })
        .catch(err=>{
            setLoading(false)
            console.log("Apps err: ", err)
        })


  }

  const handleAdd=async()=>{
    await setActive("add application")
    setActive(null)
  }

  const handleRevoke = async(selected)=>{
    setRevoking(true)
    const options = {
      url: APPS_API+`/applications/${selected.id}/organization-application/${org.id}`,
      headers : { authorization :`Bearer ${TOKEN}`},
      method: "DELETE",
      withCredentials: true       
    }

    axios(options)
      .then(res=>{
        setRevoking(false)
        if(res.status === 204){
          toastMessage(
            "success",
            "Access to application successfully revoked"
          )
          getApps()
        }
      })
      .catch(err=>{
        setRevoking(false)
        if(!err.response){
          return (
            toastMessage("error", "Connection failed. This could be an issue with the internet connection or the resource is currently unavailable.")
          )
        }
        console.log("Add Orgs err: ", err.response)
        toastMessage(
          "error",
          err.response.data ? err.response.data.message  : err.message ? err.message : "Failed. Please try again"
        )

      })

  }

  const headers = [
    { title: "App Name", key: "name" },
    { title: "App URL", key: "applicationUrl" },
    // { title: "Date Created", key: "applicationUrl" },
    { title: "Action", key: "action" },
  ]
  return (
    <>
    <div className='card-body'>
        <Table
            isLoading={loading}
            data={apps}
            headers={headers}
            addButton={true}
            handleAdd={handleAdd}
            actions={[
                {
                  name: 
                    revoking ?
                    <Spinner center size="sm" /> :
                    "Revoke Access",
                  onClick: (e)=>handleRevoke(e)
                }
            ]}
        />
    </div>
    <div className="modal-footer">
        <Button
            className='btn-secondary'
            text="Close"
            onClick={handleClose}
        />
    </div>
    </>
  )
}

export default OrganizationApps
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button } from '../../components/Button'
import { Input } from '../../components/Input'
import { APPS_API, TOKEN } from '../../data/api'
import toastMessage from '../../utils/toastMessage'

const App = (props) => {
  const { handleClose, app, getApps } = props

  const defaultApp = {
    name: "",
    applicationUrl: "",
    // logo: ""
  }

  const [application, setApplication] = useState(defaultApp)
  const [err, setErr] = useState({})
  const [submitting, setSubmitting] = useState(false)

  useEffect (()=>{
    if(app&&app.id){
      setApplication(app)
    }
  }, [app])

  const handleInput=(e)=>{
    if(!e || !e.target) return

    const { name, value } = e.target

    if(err[name])
      delete err[name]
    
    setApplication(prev=>({
      ...prev,
      [name]: value
    }))

  }

  const validateApp = async()=>{
    let valid = true, error = {}
    const { name, applicationUrl } = application

    if(!name || name === ""){
      valid = false
      error.name = "Application Name must be provided"
    }

    if(!applicationUrl || !applicationUrl === ""){
      valid = false
      error.applicationUrl = "Application URL must be provided"
    }

    await setErr(prev=> ({ ...prev, ...error}))
    return valid
  }

  const handleSubmit = async()=>{
    const isValid = await validateApp()

    if(!isValid) return
    setSubmitting(true)

    let url = app&&app.id ? APPS_API+"/applications/"+app.id : APPS_API+"/applications"
    let method = app&&app.id ? "PATCH" : "POST"

    const {
      name,
      applicationUrl
    } = application

    const options = {
      url,
      method,
      withCredentials: true,
      data: {
        name,
        applicationUrl
      },
      headers : { authorization :`Bearer ${TOKEN}`}
    }

    axios(options)
      .then(res=>{
        setSubmitting(false)
        if(res.status === 201 || res.status === 200){
          toastMessage(
              "success",
              app&&app.id ?
              "Application updated successfully":
              "Application added successfully"
          )
          handleClose()
          setApplication(defaultApp)
          getApps()
        }
      })
      .catch((err)=>{
        setSubmitting(false)
        if(!err.response){
          return (
            toastMessage("error", "Connection failed. This could be an issue with the internet connection or the resource is currently unavailable.")
          )
        }
        toastMessage(
          "error",
          err.response.data ? err.response.data.message  : err.message ? err.message : "Failed. Please try again"
        )
      })
  }

  return (
    <div className='card-body application-body'>
      <div className="top">
        <div onClick={()=>(handleClose(), setApplication(defaultApp))} className='close-btn'>
          <img src="/assets/close-square.svg" alt="CLOSE" />
        </div>
      </div>

      <form className='app-form'>
        {
          app&&app.name ?
          <h1>Edit Application </h1> :
          <h1>Add an Application</h1>
        }
        <p>
          Enter ALL the details below to complete operation:
        </p>

        <Input
          name="name"
          value={application.name}
          error={err.name}
          onChange={handleInput}
          required
          label='Application name'
        />

        <Input
          name='applicationUrl'
          onChange={handleInput}
          error={err.applicationUrl}
          required
          value={application.applicationUrl}
          label='Application url'
        />

        {/* <Input
          value={application.logo}
          placeholder='Application Logo'
        /> */}
        <Button
          className="btn-primary"
          text="Submit"
          isSubmitting={submitting}
          onClick={handleSubmit}
        />
      </form>

    </div>
  )
}

export default App
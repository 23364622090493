import React from 'react'
import Pagination from '../Pagination'
import Header from '../Header'
import Spinner from '../Spinner'
import find from "../../utils/find"
import "./style.css"
import moment from 'moment'
import { DropdownButton } from '../Button'
import { Link } from 'react-router-dom'

const Table = (props) => {
  const { 
    pagination, 
    headers, 
    data,  
    isLoading,
    rowPress,
    searchText,
    handleSearch,
    handleFilter,
    handleAdd,
    handleDownload,
    handlePagination,
    addButton,
    filterButton,
    actions,
    downloadButton
  } = props

  return (
    <>
    <div className="table-header">
        <Header 
            searchText={searchText}
            showSearch={handleSearch ? true : false}
            handleSearch={handleSearch}
            addButton={addButton}
            filterButton={filterButton}
            downloadButton={downloadButton}
            handleAdd={handleAdd}
            handleFilter={handleFilter}
            handleDownload={handleDownload}
        />
    </div>
    <div className="table-responsive">
        <table className="table table-bordered table-condensed table-hover table-border">
            <thead>
                <tr>
                    {
                        headers&&headers.map((header, index)=>{
                            return (
                                <th key={index}>{header.title}</th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {
                    isLoading ? 
                    <tr colSpan={headers.length}>
                        <td colSpan={headers.length} align="center" style={{ heigth: "3rem"}}>
                            <Spinner center={false} height={"50vh"} size="md" />
                        </td>
                    </tr>
                    :
                    (!data || data&&data.length === 0) ?
                    <tr colSpan={headers.length} align="center">
                        <td colSpan={headers.length}>No data found</td>
                    </tr>
                    :
                    data&&data.map((item, index)=>{
                        let hasAction = false

                        return (
                            <tr key={index}>
                                {
                                    headers.map((header, index)=>{
                                        hasAction = header.key==="action"
                                        let date = moment(item[header.key]).format(
                                            header.formatTime
                                        );
                                        let findText = find(item, header.key);
                                        const text =
                                            header.isMoment &&
                                            item[header.key] &&
                                            item[header.key] !== ""
                                              ? date === "Invalid date"
                                                ? findText
                                                : date
                                              : findText;
                                        return (
                                            <>
                                            {
                                                !hasAction &&
                                                <td 
                                                    key={Math.random()+index}
                                                    className="data table-td"
                                                    onClick={() =>
                                                        header.key !== "action"
                                                          ? rowPress ? rowPress(item) : null
                                                          : null
                                                          
                                                    }
                                                    title={text}
                                                >
                                                    {
                                                        text&&Array.isArray(text) ?
                                                        text.map(t=> {
                                                            return (
                                                                <span>{t.name ? t.name : t}</span>
                                                            )
                                                        })
                                                        :
                                                        <span>{text}</span>
                                                    }

                                                </td>
                                            }
                                            {
                                                hasAction &&
                                                <td>
                                                  <DropdownButton
                                                    text="Action"
                                                    className="btn-default btn-sm drop-btn"
                                                    hideFilterIcon
                                                  >
                                                    {actions &&
                                                      actions.map((action, a) => {
                                                        return (
                                                          <Link
                                                            className="dropdown-item"
                                                            to={action.route ? action.route : "#"}
                                                            onClick={() => action.onClick(item)}
                                                          >
                                                            {action.name}
                                                          </Link>
                                                        );
                                                      })}
                                                  </DropdownButton>
                                                </td>
                                            }
                                            </>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    </div>
    {
        pagination&&pagination.totalCount >= 1 &&
        <Pagination 
            pagination={pagination}
            handlePagination={handlePagination}
        />
    }
    </>
  )
}

export default Table
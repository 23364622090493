import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Modal from '../../components/Modal'
import Table from '../../components/Table'
import { ORGANIZATIONS_API, TOKEN } from '../../data/api'
import Organization from './Organization'
import ViewOrganization from './ViewOrganization'


const OrganizationsPage = () => {

  const [organizations, setOrgs] = useState([])
  const [pagination, setPagination] = useState({})
  const [searchText, setSearch] = useState("")
  const [loading, setLoading] = useState(true)
  const [showAdd, setShowAdd] = useState(false)
  const [showView, setShowView] = useState(false)
  const [selected, setSelected] = useState()
  const[showFIlter, setShowFilter] = useState(false)

  useEffect(()=>{

    getOrganizations()

  }, [])


  const getOrganizations = async(params)=>{

    setLoading(true)

    const options = {
      url: ORGANIZATIONS_API+"/organizations",
      headers : { authorization :`Bearer ${TOKEN}`},
      method: "GET",
      withCredentials: true,
      params: {
        limit: 15,
        ...params
      }       
    }

    axios(options)
    .then(res=>{
      setLoading(false )
      if(res.data){
        let pg = {}
        
        pg.pageNumber = res.data.page
        pg.pageCount = res.data.totalPages
        pg.pageNumber = res.data.page
        pg.pageLimit = res.data.limit
        pg.totalCount = res.data.totalResults
        
        setOrgs(res.data.results)
        setPagination(pg)
      }
    })
    .catch(err=>{
      setLoading(false )
      console.log("Orgs err: ", err)
    })
  }

  const handleSearch = async(e)=>{
    setSearch(e.target.value)
  }

  const handleAdd = ()=>{
    setShowAdd(true)
  }

  const handleFilter = ()=>{
    setShowFilter(true)
  }

  const handleDownload = async()=>{}

  const handleEdit = async(org)=>{
    await setSelected(org)
    setShowAdd(true)
  }

  const handleView = async(org)=>{
    await setSelected(org)
    setShowView(true)
  }

  const handleViewClose = ()=>{
    setSelected(null)
    setShowView(false)
    setShowAdd(false)
  }

  const handlePagination =(page)=>{
    setLoading(true)
    getOrganizations({ page })
  }

  const headers = [
    { title: "Name", key: "name" },
    { title: "Contact First Name", key: "contactPersonFirstName" },
    { title: "Last Name", key: "contactPersonLastName" },
    { title: "Email", key: "email" },
    { title: "Phone", key: "phone" },
    { title: "Address", key: "address" },
    { title: "Website", key: "websiteUrl" },
    { title: "Action", key: "action" },
  ]

  return (
    <>
      <Table 
        data={organizations}
        pagination={pagination}
        handlePagination={handlePagination}
        headers={headers}
        searchText={searchText}
        handleSearch={handleSearch}
        handleAdd={()=>setShowAdd(true)}
        handleFilter={handleFilter}
        handleDownload={handleDownload}
        isLoading={loading}
        addButton={true}
        filterButton={true}
        downloadButton={true}
        rowPress={handleView}
        actions={[
          {
            name: "View",
            onClick: (e)=>handleView(e)
          },
          {
            name: "Edit",
            onClick: handleEdit
          }
        ]}
      />

      <Modal 
        show={showAdd}
        title={selected ? "Edit Organization" : "Add Organization"}
        handleClose={()=> handleViewClose()}
      >
        <Organization
          getOrgs={getOrganizations}
          selectedOrg={selected}
          handleClose={()=> handleViewClose()}
        />
      </Modal>



      <Modal 
        show={showView}
        title="View Organization"
        handleClose={()=> handleViewClose()}
      >
        <ViewOrganization
          getOrgs={getOrganizations}
          selectedOrg={selected}
          handleClose={()=>handleViewClose()}
        />
      </Modal>

      

    </>
  )
}

export default OrganizationsPage